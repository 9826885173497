import React from 'react';
import { isEmpty } from 'lodash';
import firebase from 'firebase/app';
import { db } from '@/vars/firebase';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { get } from 'lodash';
import { TRANSACTION } from '@/vars';
import Typography from '@material-ui/core/Typography';

export const checkField = (tempField, consoleError) => {
  const field = tempField[0];

  if (field.length > 15) {
    consoleError.push('cannot add extra column in this document');
  }
  if (field[0] !== 'id') {
    consoleError.push('The first column should be id');
  }
  if (field[1] !== 'fullName') {
    consoleError.push('The second column should be fullName');
  }
  if (field[2] !== 'cardNumber') {
    consoleError.push('The third column should be cardNumber');
  }
  if (field[3] !== 'employeeNumber') {
    consoleError.push('The forth column should be employeeNumber');
  }
  if (field[4] !== 'credit') {
    consoleError.push('The fifth column should be credit');
  }
  if (field[5] !== 'recurringCredit') {
    consoleError.push('The fifth column should be recurring credit');
  }
  if (field[6] !== 'type.id') {
    consoleError.push('The sixth column should be type.id');
  }
  if (field[7] !== 'type.name') {
    consoleError.push('The seventh column should be type.name');
  }
  if (field[8] !== 'email') {
    consoleError.push('The eighth column should be email');
  }
  if (field[9] !== 'mobile') {
    consoleError.push('The nineth column should be mobile');
  }
  if (field[10] !== 'isActive') {
    consoleError.push('The tenth column should be isActive');
  }
  if (field[11] !== 'createdAt') {
    consoleError.push('The forth column should be createdAt');
  }
  if (field[12] !== 'updatedAt') {
    consoleError.push('The thirteenth column should be updatedAt');
  }
  if (field[13] !== 'recurringCreditType') {
    consoleError.push('The fourteenth column should be recurringCreditType');
  }
  if (field[14] !== 'costCenter') {
    consoleError.push('The fifteenth column should be costCenter');
  }
  return consoleError;
};

export const randomIdMaker = () => {
  const length = 28;
  var result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convert2string = errors => {
  let messages = '';
  errors.map(error => {
    messages += error + ';';
  });
  return messages;
};

const getEmployeeType = async companyId => {
  var employeeTypeRef = db.collection('employeeTypes');
  if (companyId != null) employeeTypeRef = employeeTypeRef.where('client', '==', companyId);
  const employeeSnapshots = await employeeTypeRef.get();
  const datas = employeeSnapshots.docs.map(doc => {
    const data = doc.data();
    return { type_id: doc.id, type_name: data.name };
  });
  return datas;
};

export const csv2json = async (clientId, tempResult, consoleError) => {
  let jsonObjectId = [];
  let jsonObjectArray = [];
  let jsonObject = {};
  let count = 2;

  const companyId = await getCompanyId(clientId);
  const employeeTypeList = await getEmployeeType(companyId);

  tempResult.forEach(result => {
    const checkName = name => {
      if (isEmpty(name)) {
        consoleError.push(`row ${count} column B: name cannot left empty`);
        return '';
      } else return name;
    };

    const checkCard = cardNumber => {
      if (isEmpty(cardNumber)) {
        consoleError.push(`row ${count} column C: card number cannot left empty`);
        return '';
      } else if (isNaN(cardNumber)) {
        consoleError.push(`row ${count} column C: card number can only contain digits`);
        return '';
      } else return cardNumber;
    };

    const generatedTimeStamp = () => {
      return firebase.firestore.FieldValue.serverTimestamp();
    };

    const checkCredit = credit => {
      if (isEmpty(credit)) {
        consoleError.push('row ' + count + ' column E: credit should not be empty');
        return '';
      } else if (isNaN(credit)) {
        consoleError.push('row ' + count + ' column E: credit should be all digits');
        return '';
      } else {
        return Number(credit);
      }
    };

    const checkRecurringCredit = recurringCredit => {
      if (isNaN(recurringCredit)) {
        consoleError.push('row ' + count + ' column F: Recurring credit should be all digits');
        return '';
      } else {
        return Number(recurringCredit);
      }
    };

    const checkTypeId = (typeId, typeName) => {
      if (!isEmpty(typeId)) return typeId;
      // if check like this, then the type name should be unique.
      const idFirestore = employeeTypeList.find(employee => employee.type_name === typeName);
      if (!idFirestore) consoleError.push('row ' + count + ' column G: type.id not found.');
      return idFirestore ? idFirestore.type_id : '';
    };

    const checkTypeName = typeName => {
      if (!isEmpty(typeName)) return typeName;
      consoleError.push('row ' + count + ' column H: type.name cannot be empty.');
      return '';
    };

    for (let i = 0; i < result.length; i++) {
      if (result[i]) {
        jsonObject = {
          fullName: checkName(result[1]),
          cardNumber: checkCard(result[2]),
          employeeNumber: result[3],
          credit: checkCredit(result[4]),
          recurringCredit: checkRecurringCredit(result[5]),
          client: companyId,
          type: {
            id: checkTypeId(result[6], result[7]),
            name: checkTypeName(result[7]),
          },
          email: result[8],
          mobile: result[9],
          isActive: true,
          updatedAt: generatedTimeStamp(),
          recurringCreditType: result[13],
          costCenter: result[14],
        };
        //one array store the id
        jsonObjectId.push(result[0].toString());
        //one array store the object
        jsonObjectArray.push(jsonObject);
        break;
      }
    }
    count++;
  });
  return [jsonObjectId, jsonObjectArray, consoleError];
};

export const getCompanyId = async clientid => {
  let companyId = null;
  await db
    .collection('users')
    .doc(clientid)
    .get()
    .then(async snapshot => {
      companyId = await snapshot.data().client.id;
    });
  return companyId;
};

// get the product type and product name
export const productType = async post => {
  const productId = post.product.categoryId;
  const getProductTypeSnap = await db
    .collection('categories')
    .doc(productId)
    .get();
  if (getProductTypeSnap.exists) {
    const productType = getProductTypeSnap.data().name;
    post.productTypeName = productType;
  } else {
    post.productTypeName = 'Product Type Not Found';
  }
  return post;
};

export const renderDate = record => {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Jun',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var date = get(record, 'date');

  if (date != null) {
    const time = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    return time;
  } else return 'N/A';
};

export const renderTime = record => {
  var time = get(record, 'date');
  if (time != null) {
    const timeClear = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds();
    return timeClear;
  } else return 'N/A';
};

export const getProductName = record => {
  var productName = get(record, 'product.name');
  if (productName == null) return 'N/A';
  return productName;
};

export const getPrice = record => {
  var price = get(record, 'amount');
  if (price == null) return 'N/A';
  return price;
};

export const getPriceInTransaction = record => {
  const priceInTransaction = get(record, 'product.price');
  if (priceInTransaction == null) return '-';
  return priceInTransaction;
};

export const renderProductSlot = record => {
  var rowArray = ['A', 'B', 'C', 'D', 'E', 'F'];
  var productSlot = get(record, 'product.slot');
  if (productSlot == null) return 'N/A';
  var row = Math.floor(productSlot / 12);
  var column = productSlot % 12;
  return `${rowArray[row]}${column}`;
};

export const renderGateWayField = record => {
  if (get(record, 'payment.gatewayID')) {
    return TRANSACTION.GATEWAY[`${get(record, 'payment.gatewayID')}`];
  }
  if (get(record, 'payment.cash')) {
    return 'Cash';
  }
  if (get(record, 'payment.terminal')) {
    return 'Paywave';
  }
  if (get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.productCode')) {
    return `${get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.productCode').toString()}`;
  }
  if (get(record, 'payment.virclePaymentDetail')) {
    return `Vircle`;
  }
  return 'others';
};

export const renderTransactionStatus = record => {
  switch (get(record, 'status')) {
    case 1:
      return <Typography style={{ color: '#00C07F', fontSize: 12 }}>Successful </Typography>;
    case 0:
      return <Typography style={{ color: '#FF6562', fontSize: 12 }}>Failed</Typography>;
    case -1:
      return <Typography style={{ color: '#C0C0C0', fontSize: 12 }}>Canceled</Typography>;
    case -2:
      return <Typography style={{ color: '#FF6562', fontSize: 12 }}>Dropped</Typography>;
    case -3:
      return <Typography style={{ color: '#FF6562', fontSize: 12 }}>Void</Typography>;
    case -4:
      return <Typography style={{ color: '#FF6562', fontSize: 12 }}>DispensingEr</Typography>;
    default:
      return <Typography style={{ fontSize: 12 }}>Others</Typography>;
  }
};

export const statusForExcelReport = status => {
  switch (status) {
    case 1:
      return 'Successful';
    case 0:
      return 'Failed';
    case -1:
      return 'Canceled';
    case -2:
      return 'Dropped';
    case -3:
      return 'Void';
    case -4:
      return 'DispensingError';
  }
};

export const getTotalCashReceived = record => {
  const cashReceived = get(record, 'cashInDetails.cashInTotal');
  if (cashReceived == null) return '';
  return cashReceived;
};

export const getTotalCashReturned = record => {
  const cashReturned = get(record, 'cashOutDetails.cashOutTotal');
  if (cashReturned == null) return '';
  return cashReturned;
};

export const getTransId = record => {
  const transId = get(record, 'payment.transactionId');
  if (transId == null) return '';
  return transId;
};

export const getRefId = record => {
  const refNo = get(record, 'payment.refNo');
  if (refNo == null) return '';
  return refNo;
};

// get the employee type
export const employeeType = async post => {
  const employeeId = post.employee.id;
  const getEmployeeTypeSnap = await db
    .collection('employees')
    .doc(employeeId)
    .get();

  if (getEmployeeTypeSnap.exists) {
    const employeeType = getEmployeeTypeSnap.data();
    const getEmployeeType = employeeType.type.name;
    post.employeeTypeName = getEmployeeType.toString();
  } else {
    post.employeeTypeName = 'Employee Not Found';
  }
  return post;
};

export const productDetails = async post => {
  const productId = post.product.id;
  const productSnapshot = await db
    .collection('products')
    .doc(productId)
    .get();
  if (productSnapshot.exists) {
    const product = productSnapshot.data();
    const getName = product.name;
    const getSku = product.sku;
    const getSerial = product.serialNumber;
    if (getSku != null) {
      post.productSku = getSku.toString();
    } else post.productSku = 'N/A';
    if (getSerial != null) {
      post.productSerial = getSerial.toString();
    } else post.productSerial = 'N/A';
    if (getName != null) {
      post.productName = getName.toString();
    } else post.productName = 'N/A';
  } else {
    post.productName = 'product not found';
    post.productSku = 'product not found';
    post.productSerial = 'product not found';
  }
  return post;
};

// export MRO transaction as csv file
export const exportCSV = datas => {
  jsonExport(
    datas,
    {
      headers: [
        //arrange
        'date',
        'time',
        'machine.machineUUID',
        'employeeTypeName',
        'employee.fullName',
        'scanCode',
        'productTypeName',
        'product.name',
        'productName',
        'productSku',
        'productSerial',
        'product.slotId',
        'quantity',
        'amount',
      ],
      rename: [
        'Date',
        'Time',
        'Machine UUID',
        'Employee Type',
        'Employee Name',
        'Employee Card Number',
        'Product Category',
        'Product Name (Transaction)',
        'Product Name (Current)',
        'Product Sku',
        'Product Serial',
        'Slot',
        'Quantity',
        'Credit',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'MRO Transactions');
    }
  );
};
