import React, { useState } from 'react';
import { ReferenceInput, DateInput } from 'react-admin';
import moment from 'moment';
import { get, inRange, isUndefined } from 'lodash';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { SelectInput as CustomeSelectInput, Filter } from '@/ra';
import { USER } from '@/vars';
import { useAuth } from '@/utils/auth';

const TransactionFilter = props => {
  const { permissions } = useAuth();
  /** using string instead of number is to prevent failed transaction with status 0 no show issue. */
  const [statusSelected, setStatusSelected] = useState(permissions.role === USER.ROLE.CLIENT ? '1' : '2');

  const parseFormDataToFilters = data => {
    /** set a default status for client role to success as default */
    let filters = permissions.role === USER.ROLE.CLIENT ? { status: 1 } : {};
    const { machineId, dateStart, dateEnd, status } = data;

    if (machineId) {
      filters['machine.id'] = machineId;
    }

    if (dateStart || dateEnd) {
      const date = [];
      if (dateStart)
        date.push({
          operator: '>=',
          value: +moment(dateStart).startOf('day'),
          isDate: true,
        });
      if (dateEnd)
        date.push({
          operator: '<=',
          value: +moment(dateEnd).endOf('day'),
          isDate: true,
        });
      filters.date = date;
    }

    if (!isUndefined(status)) {
      const statusInNumber = parseInt(status);
      if (inRange(statusInNumber, -3, 2)) {
        filters.status = statusInNumber;
      }
      if (statusInNumber === 2) {
        filters.status = 2;
      }
    }

    return filters;
  };

  const parseFiltersToFormData = filters => {
    let formData = {};

    if (get(filters, 'machine.id')) {
      formData['machineId'] = get(filters, 'machine.id');
    }

    get(filters, 'date', []).forEach(({ operator, value }) => {
      if (operator === '>=') formData.dateStart = moment(value).format('Y-M-D');
      else formData.dateEnd = moment(value).format('Y-M-D');
    });

    if (get(filters, 'status')) {
      formData['status'] = get(filters, 'status');
    }

    return formData;
  };

  const machineInputFilter = {
    ...useDefaultFilter('client.id'),
    isDeleted: false,
  };

  const statusChoices = [
    { id: '1', name: 'Success' },
    { id: '0', name: 'Failed' },
    { id: '-1', name: 'Canceled' },
    { id: '-2', name: 'Refunded' },
    { id: '-3', name: 'Void' },
    { id: '-4', name: 'DispensingEr'},
    { id: '2', name: 'ALL' },
  ];

  const handleChange = event => {
    if (!event.target.value) return;
    setStatusSelected(event.target.value);
  };

  return (
    <Filter parseFiltersToFormData={parseFiltersToFormData} parseFormDataToFilters={parseFormDataToFilters} {...props}>
      <ReferenceInput
        label="Machine"
        source="machineId"
        reference="machines"
        filter={machineInputFilter}
        filterToQuery={() => {}}
        sort={{ field: 'machineUUID' }}
        perPage={99999}
        allowEmpty
      >
        <CustomeSelectInput optionText="machineUUID" emptyText="Show all machines" margin="normal" />
      </ReferenceInput>
      <DateInput source="dateStart" variant="outlined" fullWidth margin="normal" />
      <DateInput source="dateEnd" variant="outlined" fullWidth margin="normal" />
      <CustomeSelectInput
        source="status"
        fullWidth
        margin="normal"
        choices={statusChoices}
        value={statusSelected || ''}
        onClick={e => handleChange(e)}
      />
    </Filter>
  );
};

TransactionFilter.defaultProps = {
  searchKeys: ['payment.transactionId', 'machine.machineUUID', 'product.name', 'amount'],
};

export default TransactionFilter;
