import React, { useState } from 'react';
import { ReferenceInput, required, SelectInput, minValue, maxValue, BooleanInput } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';
import { constant, get } from 'lodash';

import { USER } from '@/vars';
import { useAuth } from '@/utils/auth';
import { Button } from '@/components';
import { TextInput } from '@/components/input';
import { NumberInput, ReferenceRecord } from '@/ra';
import firebase from 'firebase/app';

const EmployeeTypeForm = ({ save, saving, record, redirect, ...rest }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const initialValues = { isActive: true, ...record };

  const [paramsList, setParamsList] = useState(record.params ? record.params : [{ tag: '', value: '' }]);

  const handleAddInputField = () => {
    setParamsList([...paramsList, { tag: '', value: '' }]);
  };

  const handleRemoveInputField = index => {
    const list = [...paramsList];
    list.splice(index, 1);
    setParamsList(list);
  };

  const handleInputOnChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...paramsList];
    list[index][name] = value;
    setParamsList(list);
  };

  const onSubmit = async data => {
    // use trim to remove white spaces.
    data.cardNumber = data.cardNumber ? data.cardNumber.trim() : '';

    // if cardNumber after trim is empty, show alert
    if (!data.cardNumber || data.cardNumber.length < 1) {
      alert('Card Number cannot be empty.');
      return;
    }

    // set credit to 0 if it is null to prevent NaN saved to database
    data.credit = data.credit != null ? data.credit : 0;
    if (typeof data.credit !== 'number' || data.credit < 0) {
      alert('Credit can only be a number and cannot be less than 0.');
      return;
    }

    // recurring credit cannot be 0 if recurringCreditType is not none, need value to do the recurring.
    if (
      data.recurringCreditType !== 'none' &&
      (typeof data.recurringCredit !== 'number' || data.recurringCredit <= 0)
    ) {
      alert('Recurring credit cannot be less than zero.');
      return;
    }

    const { cardNumber: currentCardNumber } = initialValues;
    const { cardNumber: updatedCardNumber } = data;

    data.employeeNumber = data.employeeNumber != null ? data.employeeNumber : ''; // set employee number to empty string if it is null

    const currentEmployeeNumber = initialValues.employeeNumber || '';
    const updatedEmployeeNumber = data.employeeNumber;

    // remove empty tag and value
    data.params = paramsList.filter(item => item.tag && item.value);
    delete data.tag;
    delete data.value;

    const client = data.client;

    // if the card number is changed, check if the new card number is already used for other employee
    if (currentCardNumber !== updatedCardNumber) {
      try {
        const cardNumberAlreadyUsed =
          (await firebase
            .firestore()
            .collection('employees')
            .where('cardNumber', '==', updatedCardNumber)
            .where('client', '==', client)
            .get()).size > 0;

        if (cardNumberAlreadyUsed) {
          alert('Card Number is already taken');
          return;
        }
      } catch (error) {
        alert('Error checking card number');
        return;
      }
    }

    // if the employee number is changed, check if the new employee number is already used for other employee
    if (updatedEmployeeNumber.length > 0 && currentEmployeeNumber !== updatedEmployeeNumber) {
      try {
        const employeeNumberAlreadyUsed =
          (await firebase
            .firestore()
            .collection('employees')
            .where('employeeNumber', '==', updatedEmployeeNumber)
            .where('client', '==', client)
            .get()).size > 0;

        if (employeeNumberAlreadyUsed) {
          alert('Employee Number is already taken');
          return;
        }
      } catch (error) {
        alert('Error checking employee number');
        return;
      }
    }

    if (data.recurringCreditType === 'none') data.recurringCredit = 0; // if recurringCreditType set to none, set the recurring credit to 0

    save(data, redirect);
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit, values, modified, form, ...rest }) => {
        return (
          <form name="myForm" onSubmit={handleSubmit}>
            <CardHeader title="Employee details" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput label="Full name" name="fullName" validate={required('Please enter a full name.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Card number" name="cardNumber" validate={required('Please enter a card number.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Employee number" name="employeeNumber" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Email" name="email" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Mobile" name="mobile" />
                </Grid>
                {isAdmin ? (
                  <Grid item xs={12}>
                    <ReferenceInput
                      source="client"
                      reference="clients"
                      filterToQuery={() => {}}
                      onChange={() => {
                        // reset typeState after client value change
                        const typeState = form.getFieldState('type.id');
                        if (typeState) typeState.change('');
                      }}
                    >
                      <SelectInput
                        optionText="companyName"
                        variant="outlined"
                        fullWidth
                        margin="none"
                        perPage={99999}
                        validate={required('Please select a client.')}
                      />
                    </ReferenceInput>
                  </Grid>
                ) : (
                  <Field component="input" type="hidden" name="client" initialValue={permissions.client_id} />
                )}
                {values.client && (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Type"
                      source="type.id"
                      reference="employeeTypes"
                      filterToQuery={() => {}}
                      filter={{ client: values.client }}
                    >
                      <SelectInput
                        optionText="name"
                        variant="outlined"
                        fullWidth
                        margin="none"
                        perPage={99999}
                        validate={required('Please select a type.')}
                      />
                    </ReferenceInput>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput label="Cost Center" name="costCenter" />
                </Grid>
                {paramsList.length > 0 &&
                  paramsList.map((item, index) => (
                    <Grid container item xs={12} spacing={3} key={index}>
                      <Grid item xs={12} md={5}>
                        <TextInput
                          label="Tag"
                          name="tag"
                          value={item.tag}
                          onChange={e => handleInputOnChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextInput
                          label="Value"
                          name="value"
                          value={item.value}
                          onChange={e => handleInputOnChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          style={{ width: '100%', height: 56 }}
                          variant="contained"
                          onClick={() => handleRemoveInputField(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                {get(values, 'type.id') && (
                  <ReferenceRecord id={get(values, 'type.id')} reference="employeeTypes">
                    {typeRecord => {
                      let initialCredit = get(modified, 'type.id') ? get(typeRecord, 'credit') : values.credit;
                      return (
                        <Grid item xs={12}>
                          <NumberInput
                            label="Current Credit"
                            name="credit"
                            validate={[
                              required('Please enter a credit.'),
                              minValue(0, 'Credit cannot be lower than 0.'),
                              maxValue(999999999, 'Credit cannot be more than 9 digits.'),
                            ]}
                            defaultValue={Math.round(initialCredit * 100) / 100}
                          />
                          <Field component="input" type="hidden" name="type.name" initialValue={typeRecord.name} />
                        </Grid>
                      );
                    }}
                  </ReferenceRecord>
                )}
                <Grid item xs={12} md={6}>
                  <SelectInput
                    variant="outlined"
                    fullWidth
                    margin="none"
                    source="recurringCreditType"
                    label="Recurring Credit Type"
                    reference="recurringCreditType"
                    choices={[
                      { id: 'none', name: 'None' },
                      { id: 'clearOff', name: 'Clear Off' },
                      { id: 'cumulativeCredit', name: 'Cumulative Credit' },
                    ]}
                    defaultValue={values.recurringCreditType || 'none'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {values.recurringCreditType &&
                    typeof values.recurringCreditType !== 'undefined' &&
                    values.recurringCreditType != 'none' && (
                      <NumberInput
                        label="Recurring Credit"
                        name="recurringCredit"
                        defaultValue={Math.round(values.recurringCredit * 100) / 100}
                        validate={[
                          required('Please enter your recurring credit.'),
                          minValue(0.01, 'The minimum recurring credit must more than 0.'),
                          maxValue(999999999, 'Recurring credit cannot be more than 9 digits.'),
                        ]}
                      />
                    )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <BooleanInput source="isActive" label="Active" />
                </Grid>
                <Field component="input" type="hidden" name="params" initialValue={paramsList} />
                <Grid item xs={12} md={12}>
                  <Button variant="contained" color="primary" onClick={handleAddInputField}>
                    Add Tag
                  </Button>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="primary" loading={saving}>
                Save
              </Button>
            </CardActions>
          </form>
        );
      }}
    </Form>
  );
};

export default EmployeeTypeForm;
