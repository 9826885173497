import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  DeleteButton,
  CreateButton,
  ExportButton,
  ReferenceField,
  downloadCSV,
  FunctionField,
} from 'react-admin';
import { colors } from '@material-ui/core';
import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { ActionMenu, Filter } from '@/ra';
import { Page, Label } from '@/components';
import Dialog from '@/dialogs/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import jsonExport from 'jsonexport/dist';

const StatusField = ({ record, ...rest }) => {
  const { isActive } = record;
  const text = isActive ? 'active' : 'inactive';
  const color = isActive ? colors.green[500] : colors.grey[600];
  return <Label color={color}>{text}</Label>;
};

const exporter = posts => {
  const datas = posts.map(post => {
    const { client, ...data } = post;
    return data;
  });
  jsonExport(
    datas,
    {
      headers: [
        'id',
        'fullName',
        'cardNumber',
        'employeeNumber',
        'credit',
        'recurringCredit',
        'type.id',
        'type.name',
        'email',
        'mobile',
        'isActive',
        'createdAt',
        'updatedAt',
        'recurringCreditType',
        'costCenter',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'employees');
    }
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  filter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filter={filter} exporter={exporter} />
    <Dialog />
  </Toolbar>
);

const AdminView = ({ props, filter, searchFilter }) => {
  return (
    <>
      <List {...props} filter={filter} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
        <Datagrid>
          <TextField source="fullName" label="Full name" />
          <ReferenceField label="Company" source="client" reference="clients" link={false}>
            <TextField source="companyName" />
          </ReferenceField>
          <TextField source="employeeNumber" label="Employee no." />
          <TextField source="cardNumber" label="Card no." />
          <TextField source="type.name" label="Type" />
          <TextField source="costCenter" label="Cost Center" />
          <FunctionField
            label="Current Credit"
            render={record => {
              let credit = parseFloat(record.credit) || 0;
              let roundedValue = Math.round(credit * 100) / 100;
              return roundedValue.toFixed(2);
            }}
          />
          <NumberField source="recurringCredit" label="Recurring Credit" />
          <StatusField label="Status" />
          <ActionMenu label="Actions">
            <EditButton icon={null} />
            <DeleteButton icon={null} />
          </ActionMenu>
        </Datagrid>
      </List>
    </>
  );
};

const ClientView = ({ props, filter, searchFilter }) => {
  return (
    <>
      <List
        {...props}
        filter={filter}
        sort={{ field: 'createdAt' }}
        filters={searchFilter}
        perPage={25}
        actions={<PostActions filter={filter} />}
      >
        <Datagrid>
          <TextField source="fullName" label="Full name" />
          <TextField source="employeeNumber" label="Employee no." />
          <TextField source="cardNumber" label="Card no." />
          <TextField source="type.name" label="Type" />
          <TextField source="costCenter" label="Cost Center" />
          <FunctionField
            label="Current Credit"
            render={record => {
              let credit = parseFloat(record.credit) || 0;
              let roundedValue = Math.round(credit * 100) / 100;
              return roundedValue.toFixed(2);
            }}
          />
          <NumberField source="recurringCredit" label="Recurring Credit" />
          <StatusField label="Status" />
          <ActionMenu label="Actions">
            <EditButton icon={null} />
            <DeleteButton icon={null} />
          </ActionMenu>
        </Datagrid>
      </List>
    </>
  );
};

const EmployeeList = props => {
  const { permissions } = useAuth();
  const filter = useDefaultFilter('client');
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const searchFilter = <Filter searchKeys={['fullName', 'employeeNumber', 'cardNumber', 'email']} />;

  const propsCompile = { props, filter, searchFilter };
  return (
    <Can module={ACCESS.MRO}>
      <Page sectionTitle="MRO" title="List employees">
        {isAdmin ? <AdminView {...propsCompile} /> : <ClientView {...propsCompile} />}
      </Page>
    </Can>
  );
};

export default EmployeeList;